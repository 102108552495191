import React from "react";
import css from "./comparison-bar.module.css";

function ComparisonBar({ data }) {
  return (
    <div className={css.root}>
      {data.map((param, index) => (
        <div key={index} className={css.param}>
          <div className={css.row}>
            <span className={css.label}>{param.label}</span>
            <span className={css.value}>{param.value}</span>
          </div>
          <div className={css.track}>
            <div
              className={css.progress}
              style={{ width: `${param.progress}%` }}
            ></div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ComparisonBar;
