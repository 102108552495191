import React from "react";
import { graphql } from "gatsby";
import Container from "@material-ui/core/Container";
import css from "./case-study.module.css";
import ComparisonBar from "../components/comparison-bar";
import Contact from "../components/contact";
import Content from "../components/content";
import Demo from "../components/demo";
import DonutChart from "../components/donut-chart";
import Layout from "../components/layout";
import Spacer from "../components/spacer";
import SEO from "../components/seo";

const Components = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter: fm } = markdownRemark;

  return (
    <Layout>
      <SEO title={fm.seoTitle} description={fm.seoDescription} />

      <Container>
        <h1
          style={{
            fontSize: 40,
            fontWeight: 400,
            margin: "32px 0",
            textAlign: "center",
          }}
        >
          {fm.page_title}
        </h1>
      </Container>

      {fm.demo && fm.demo.length && (
        <Container>
          <Demo demo={fm.demo} />
        </Container>
      )}
      <Spacer h={60} />

      {fm.metadata && fm.metadata.length > 0 && (
        <>
          <Spacer h={140} />
          <div className={css.meta}>
            {fm.metadata.map((item) => (
              <div key={item.label} className={css.meta_item}>
                <span className={css.label}>{item.label}</span>
                <span className={css.value}>{item.value}</span>
              </div>
            ))}
          </div>
          <Spacer h={80} />
        </>
      )}

      <Container>
        <div style={{ maxWidth: 720, margin: "auto" }}>
          <Content data={fm.content_md1} />
        </div>
      </Container>

      {((fm.donut && fm.donut.progress && fm.donut.text) ||
        (fm.comparison &&
          fm.comparison.data &&
          fm.comparison.data.length > 0)) && (
        <Container>
          <div style={{ maxWidth: 720, margin: "40px auto" }}>
            <div className={css.graph}>
              {fm.donut && fm.donut.progress && fm.donut.text && (
                <DonutChart data={fm.donut} />
              )}
              {fm.comparison &&
                fm.comparison.data &&
                fm.comparison.data.length > 0 && (
                  <ComparisonBar data={fm.comparison.data} />
                )}
            </div>
          </div>
        </Container>
      )}

      <Container>
        <div style={{ maxWidth: 720, margin: "auto" }}>
          <Content data={fm.content_md2} />
        </div>
      </Container>

      <div style={{ marginTop: 320 }}></div>

      <Contact />
    </Layout>
  );
};

export default Components;

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        path
        seoTitle
        seoDescription
        page_title
        content_md1
        content_md2
        donut {
          progress
          text
        }
        demo {
          input
          output {
            result
          }
          demo_type
          output_type
        }
        metadata {
          label
          value
        }
        comparison {
          data {
            label
            value
            progress
          }
        }
      }
    }
  }
`;
