import React from "react";

class DonutChart extends React.Component {
  constructor(props) {
    super(props);
    this.radius = 120;
    this.lineWidth = 16;
    this.r1 = this.radius - this.lineWidth / 2;
    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext("2d");
    const angleStart = -0.5 * Math.PI;
    const angleEnd = (this.props.data.progress / 50 - 0.5) * Math.PI;

    //Create gradient
    const gradient = ctx.createLinearGradient(0, 0, 0, this.r1 * 2);
    gradient.addColorStop(0, "#AFE1FF");
    gradient.addColorStop(1, "#4B41F9");

    //Draw circle
    ctx.beginPath();
    ctx.arc(this.radius, this.radius, this.r1, 0, 2 * Math.PI, false);
    ctx.lineWidth = this.lineWidth;
    ctx.strokeStyle = "#dfdfdf";
    ctx.stroke();

    //Draw arc
    ctx.beginPath();
    ctx.arc(this.radius, this.radius, this.r1, angleStart, angleEnd);
    ctx.strokeStyle = gradient;
    ctx.lineWidth = this.lineWidth;
    ctx.lineCap = "round";
    ctx.stroke();
  }

  render() {
    return (
      <div
        style={{
          position: "relative",
          width: this.radius * 2,
          height: this.radius * 2,
        }}
      >
        <canvas
          ref={this.canvasRef}
          width={this.radius * 2}
          height={this.radius * 2}
        />
        <div
          style={{
            display: "flex",
            textAlign: "center",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <div style={{ margin: "auto", padding: 30, position: "relative" }}>
            <span style={{ fontSize: 30, color: "#546fff" }}>
              {this.props.data.progress}%
            </span>
            <div style={{ fontSize: 14, fontWeight: 600, textAlign: "center" }}>
              {this.props.data.text}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DonutChart;

DonutChart.defaultProps = {
  progress: 0,
};
